import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/specialfind_1435_1.jpg","alt":null,"title":"Stamped Pottery "}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/special_finds/1435"}}>{`https://gabii.cast.uark.edu/data/browse/special_finds/1435`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/000146-copy.jpg","alt":"Die "}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/special_finds/146"}}>{`https://gabii.cast.uark.edu/data/browse/special_finds/146`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/specialfind_1432_1.jpg","alt":null,"title":"Stamped Pottery "}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/special_finds/1432"}}>{`https://gabii.cast.uark.edu/data/browse/special_finds/1432`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-with-background","title":"Top Finds of Area B","backgroundImage":"/assets/tomb-11_assemblage.jpg","key":"top-finds-of-area-b","parentKey":"area-b"};

  